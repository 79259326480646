import React from 'react'
import { Action, Container, Layout } from '../components'
import styled from 'styled-components'
import bg from '../images/hero.jpg'
import { graphql } from 'gatsby'
import { Flex, Box, Heading, Text } from 'rebass'
import { EventList } from '../components/eventList'
import { themeGet } from 'styled-system'
import { Absolute } from '@rebass/position'
import InstagramFeed from '../components/instagramFeed'

const Hero = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100vw;
  height: 60vh;
  max-height: 800px;
  background-image: url(${bg}),
    linear-gradient(135deg, #64237f 0%, #6d1866 25%, #79094c 75%, #90065d 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background-image: linear-gradient(
      to bottom,
      rgba(87, 27, 98, 0) 50%,
      rgba(87, 27, 98, 1) 100%
    );
  }
`

const SkewedBox = styled(Box)`
  position: relative;
  z-index: 1;

  &::before,
  &::after {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><polygon fill="white" points="100,100 100,0 0,0"/></svg>');
    background-repeat: no-repeat;
    background-size: 100vw 100%;
    display: block;
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    width: 100%;
    height: 4rem;
  }

  &::after {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><polygon fill="${themeGet(
      'colors.other'
    )}" points="100,100 100,0 0,0"/></svg>');
    top: auto;
    bottom: -4rem;
  }

  @media (min-width: 40em) {
    &::before,
    &::after {
      height: 8rem;
    }

    &:after {
      bottom: -8rem;
    }
  }
`

const IndexPage = ({ data }) => {
  return (
    <Layout inverted={true}>
      <Container py={[4, 5]} px={[3, 4]} maxWidth="80em">
        <Heading
          as="h1"
          fontSize={[4, 5, 6, 7]}
          fontWeight="normal"
          textAlign="center"
          mb={3}
          children={data.contentfulPage.title}
        />
        <Container maxWidth={[null, null, '40em', '48em']} px={0}>
          <Text
            fontSize={[2, 3, null, 4]}
            textAlign="center"
            children={data.contentfulPage.excerpt.excerpt}
          />
        </Container>
      </Container>

      <SkewedBox bg="#571B62" pt={[6, null, 7]}>
        <Hero image={data.contentfulPage.image} />

        <Absolute
          left={0}
          right={0}
          top={['2rem', '4rem']}
          mt={[-3, -4]}
          zIndex="2"
        >
          <Flex justifyContent="center">
            <Action to="/viikkotunnit">
              {data.viikkotunnit.edges[0].node.title} &rarr;
            </Action>
          </Flex>
        </Absolute>

        <Box py={6}>
          <Container maxWidth="72em">
            <EventList events={data.allContentfulEvent.edges} home />
          </Container>
          <Container maxWidth="72em" mt={[3, null, 4, 5, 6]}>
            <Heading
              as="h2"
              fontSize={[4, null, 5]}
              fontWeight="normal"
              textAlign="center"
              mb={[3, 4, 5]}
              color="white"
              children="@Instagram"
            />
            <InstagramFeed />
          </Container>
        </Box>
      </SkewedBox>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query indexQuery {
    contentfulPage(slug: { eq: "etusivu" }) {
      title
      excerpt {
        excerpt
      }
      image {
        fluid(maxWidth: 1440) {
          ...GatsbyContentfulFluid
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }

    viikkotunnit: allContentfulEvent(
      filter: { eventType: { eq: "Weekly Practise" } }
      sort: { fields: [startDate], order: DESC }
      limit: 1
    ) {
      edges {
        node {
          title
        }
      }
    }

    allContentfulEvent(
      limit: 4
      filter: { eventType: { eq: "Weekend Course" } }
      sort: { fields: [startDate], order: DESC }
    ) {
      edges {
        node {
          name
          slug
          eventType
          startDate
          endDate
          description {
            description
          }
          body {
            body
          }
          featuredImages {
            title
            description
            fluid(maxWidth: 1440, quality: 60) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
